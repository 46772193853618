<!-------------------------------------------------
description:  运维中心 设备运维 远程升级
/**
 *@author xiaosanye 
 *@date 2023/2/27
*/
--------------------------------------------------->
<template>
  <div class="page-container">
    <custom-list
      class="custom-list"
      ref="customList"
      warm-tips=""
      :search-data="searchData"
      :search-params="searchParams"
      :show-header="true"
      table-title="远程升级列表"
      select-table
      :selection-change="selectionChange"
      :table-columns="tableColumns"
      :table-operate-buttons="tableOperateButtons.filter(item => item.enabled())"
      :request-method="omManagementApi.omNewZdlInfo"
      :header-buttons="headerButtons.filter(item => item.enabled())" />
    <!-------------------------------------------------
    description:远程更新 弹窗
    --------------------------------------------------->
    <custom-dialog
      :show.sync="showRemoteUpgradeDialog"
      :components-configs="configsOfRemoteUpgrade"
      :upload-method="omManagementApi.uploadFile"
      :submit-method="omManagementApi.remoteUpdate"
      :row="currentRow"
      :submit-exclude-keys="['id']"
      dialog-title="远程更新"
      call-back-file-prop="fileName"
      :use-confirm="true"
      :confirm-title="confirmTitle"
      :confirm-desc="confirmDesc"
      :use-success-confirm="true"
      :success-confirm-title="successConfirmTitle"
      :success-confirm-desc="successConfirmDesc"
      @refresh-list="refreshList"
      @dialog-close="onDialogClose" />
    <!-------------------------------------------------
    description:更新记录 弹窗
    --------------------------------------------------->
    <update-records
      :show.sync="showUpdateRecords"
      v-if="showUpdateRecords"
      :pile-code="currentPileCode"
      @dialog-close="onDialogClose" />
  </div>
</template>

<script>
  import { code } from '@/utils';
  import regexpObj from '@/utils/regexp';
  import CustomList from '@/components/biz/CustomList.vue';
  import CustomDialog from '@/components/biz/CustomFormDialog.vue';
  import UpdateRecords from '@/views/operationManagement/components/UpdateRecords.vue';
  import { barrierPage, brandModelPage, directPage, omManagementApi } from '@/service/apis';

  export default {
    name: 'RemoteUpgradeList',
    components: { UpdateRecords, CustomDialog, CustomList },
    data() {
      return {
        version: '当前版本',
        confirmDesc:
          '平台免责申明：\n' +
          '\n' +
          '本平台作为运维平台,程序更新内容的安全性、准确性、合法性及完整性由相应第三方机构方负责，因更新内容对设备工作造成的影响，平台不承担任何责任。\n',
        successConfirmTitle: '下发成功',
        successConfirmDesc: '更新指令已下发',
        currentPileCode: '',
        currentRow: null, // 当前选择行的数据
        brandListData: [], // 归属电站数据
        OperatorListData: [], // 归属商户数据
        brandAndModelData: [], // 终端型号数据
        selectIds: [], // 选中的数据
        showRemoteUpgradeDialog: false, // 展示远程升级弹窗
        showUpdateRecords: false, // 展示更新记录
        configsOfRemoteUpgrade: [], // 远程升级配置
        searchParams: {
          brandIds: [],
          stationIdList: [],
          stationOperators: [],
        },
        dictionary: [
          {
            id: '1',
            name: 'xxxxx',
          },
          {
            id: '2',
            name: 'xxxxx',
          },
          {
            id: '3',
            name: 'xxxxx',
          },
        ],
        tableColumns: [
          { label: '电桩编码', prop: 'code', scopedSlots: { defaultTitle: () => '' } },
          { label: '电桩名称', prop: 'name', scopedSlots: { defaultTitle: () => '' } },
          { label: '归属运营商', prop: 'operatorName', scopedSlots: { defaultTitle: () => '' } },
          { label: '归属电站', prop: 'stationName', scopedSlots: { defaultTitle: () => '' } },
          { label: '品牌', prop: 'brandName', scopedSlots: { defaultTitle: () => '' } },
          { label: '型号', prop: 'brandModelName', scopedSlots: { defaultTitle: () => '' } },
          { label: '程序版本号', prop: 'version', scopedSlots: { defaultTitle: () => '' } },
        ],
        tableOperateButtons: [
          {
            enabled: () => code('maintain:alarm:update:remote'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    // this.doEdit(row);
                    // this.currentRow = { ...row };
                    console.log('-> row', row);
                    this.currentRow = {
                      id: Date.now(),
                      pileList: [row.code],
                    };
                    this.showRemoteUpgradeDialog = true;
                  }}>
                  远程更新
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('maintain:alarm:update:record'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    // this.gotoDetailPage(row);
                    console.log('更新记录');
                    this.currentPileCode = row.code;
                    this.showUpdateRecords = true;
                  }}>
                  更新记录
                </ykc-button>
              );
            },
          },
        ],
      };
    },
    created() {
      this.initConfigsOfRemoteUpgrade();
      this.requestBrandList();
      this.requestOperatorList();
      this.requestBrandAndModelList();
    },
    methods: {
      refreshList() {
        this.$refs?.customList?.searchTableList();
      },
      initConfigsOfRemoteUpgrade() {
        this.configsOfRemoteUpgrade = [
          {
            key: 'version',
            label: '程序版本号',
            id: 1,
            componentName: 'YkcInput',
            minlength: 1,
            maxlength: 30,
            placeholder: '请输入程序版本号',
            rules: () => {
              // console.log('-> ref', ref);
              // console.log('-> ctx', ctx);
              return [
                {
                  required: true,
                  message: regexpObj.regexp.input.versionNumberTypeV2.empty(),
                  trigger: 'blur',
                },
                // {
                //   pattern: regexpObj.regexp.input.versionNumberTypeV2.regexp,
                //   message: regexpObj.regexp.input.versionNumberTypeV2.error(),
                //   trigger: 'blur',
                // },
              ];
            },
            change: data => {
              console.log('-> on version data', data);
              this.version = data;
            },
          },
          {
            key: 'file',
            label: '更新文件',
            id: 2,
            componentName: 'YkcUpload',
            placeholder: '请选择更新文件',
            type: 'files',
            required: false,
            ref: 'file',
            size: 10,
            linkText: '支持上传更新压缩包.zip',
            checkInfo: { typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' },
            checkFileType: false,
            rules: ref => {
              return [
                {
                  required: true,
                  trigger: 'change',
                  validator: (rule, value, callback) => {
                    if (ref.file.fileList.length > 0) {
                      if (ref.ruleForm.$attrs.value.fileName) {
                        callback();
                      } else {
                        this.$message.warning('请等待传输完成');
                      }
                    } else {
                      callback(new Error('请选择需要导入的文件'));
                    }
                  },
                },
              ];
            },
            change(data) {
              console.log('-> on file data', data);
            },
            handleCheckMessage: (errorMessage, item) => {
              console.log('-> errorMessage', errorMessage);
              if (item?.required) {
                this.$message.error(errorMessage);
              }
            },
          },
        ];
      },
      /**
       * 获取归属商户列表
       */
      requestOperatorList() {
        directPage
          .userOperatorList({ keyword: '' })
          .then(res => {
            res?.map(item => {
              return this.OperatorListData.push({
                id: item.operatorId,
                name: item.name,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属电站列表
       */
      requestBrandList() {
        barrierPage
          .findStationList({ type: 1 })
          .then(res => {
            // console.log('获取归属电站列表+++++', res);
            res?.map(item => {
              return this.brandListData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取品牌型号列表
       */
      requestBrandAndModelList() {
        brandModelPage
          .queryForPage({ keyword: '' })
          .then(res => {
            // console.log('获取品牌型号列表+++++', res);
            const params = res.records;
            console.log('-> params：', params);
            this.brandAndModelData = params.map(item => ({
              id: item.id,
              name: item.name,
            }));
          })
          .catch(err => {
            console.log(err);
          });
      },
      selectionChange(selection, row) {
        console.log(selection, row);
        this.selectIds = selection.map(item => {
          return item.code;
        });
      },
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
        this.searchParams.flowTimeStart = event?.date[0];
        this.searchParams.flowTimeEnd = event?.date[1];
      },
      onDialogClose() {
        this.showRemoteUpgradeDialog = false;
        this.showUpdateRecords = false;
      },
    },
    computed: {
      confirmTitle() {
        return `确认将所选终端的程序更新至 ${this.version || '——'} 吗？`;
      },
      omManagementApi() {
        return omManagementApi;
      },
      headerButtons() {
        return [
          {
            enabled: () => code('maintain:alarm:update:batch'),
            label: '批量更新',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              if (!this.selectIds.length) {
                this.$message.warning('请选择需要更新的电桩');
                return;
              }
              this.currentRow = {
                id: Date.now(),
                pileList: this.selectIds,
              };
              this.showRemoteUpgradeDialog = true;
            },
          },
        ];
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'pileCode',
            label: '电桩编码',
            placeholder: '请输入电桩编码',
          },
          {
            comName: 'YkcInput',
            key: 'pileName',
            label: '电桩名称',
            placeholder: '请输入电桩名称',
          },
          {
            comName: 'YkcDropdown',
            multiple: true,
            clearable: true,
            key: 'stationOperators',
            label: '归属运营商',
            placeholder: '请选择归属运营商',
            data: [...this.OperatorListData],
          },
          {
            comName: 'YkcDropdown',
            multiple: true,
            clearable: true,
            key: 'stationIdList',
            label: '归属电站',
            placeholder: '请选择归属电站',
            data: [...this.brandListData],
          },

          {
            comName: 'YkcDropdown',
            key: 'brandIds',
            label: '品牌型号',
            multiple: true,
            clearable: true,
            placeholder: '请选择品牌型号',
            data: [...this.brandAndModelData],
          },
          {
            comName: 'YkcInput',
            key: 'version',
            label: '程序版本号',
            placeholder: '请输入程序版本号',
          },
        ];
      },
    },
  };
</script>

<style scoped></style>
