<!-------------------------------------------------
description: 通用业务组件 自定义表单弹窗
/**
 *@author xiaosanye 
 *@date 2023/2/27
*/
--------------------------------------------------->
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import YkcFormItem from '@/components/ykc-ui/form/src/ykc-form-item.vue';

  export default {
    name: 'CustomDialog',
    components: { YkcFormItem },
    props: {
      // 弹窗标题
      dialogTitle: String,
      // 展示弹窗变量
      show: {
        type: Boolean,
        default: false,
      },
      // 当前行的数据
      row: {
        type: Object,
        default: () => ({}),
      },
      // 默认数据
      data: {
        type: Object,
        default: () => ({}),
      },
      // 弹窗表单数据项 items
      componentsConfigs: {
        type: Array,
        default: () => [],
      },
      // 表单提交请求的方法
      submitMethod: {
        type: Function,
        default: () => Promise.resolve(null),
      },
      // 上传文件的方法
      uploadMethod: {
        type: Function,
        default: () => Promise.resolve(null),
      },
      callBackFileProp: {
        type: String,
        default: 'file',
      },
      customFormRules: {
        type: [Object, Array],
        required: false,
      },
      submitExcludeKeys: {
        type: Array,
        default: () => [],
      },
      //   是否使用自定义弹窗
      useConfirm: {
        type: Boolean,
        default: false,
      },
      // 确认标题
      confirmTitle: {
        type: String,
        default: '',
      },
      // 确认描述
      confirmDesc: {
        type: String,
        default: '',
      },
      useSuccessConfirm: {
        type: Boolean,
        default: false,
      },
      // 确认标题
      successConfirmTitle: {
        type: String,
        default: '',
      },
      // 确认描述
      successConfirmDesc: {
        type: String,
        default: '',
      },
    },
    render() {
      const listeners = {
        'update:show': val => {
          this.$emit('update:show', val);
        },
      };
      return (
        <ykc-drawer
          ref="customDialog"
          title={this.dialogTitle}
          show={this.showDrawer}
          {...{ on: listeners }}
          beforeEnsure={this.useConfirm ? this.onBeforeConfirm : this.submitForm}
          onClose={this.onCLose}
          beforeCancel={this.beforeCancel1}>
          <div class="drawer-main-body">
            <ykc-form
              vModel={this.ruleForm}
              {...{
                props: {
                  model: this.ruleForm,
                  rules: this.rules,
                },
              }}
              rules={this.rules}
              ref="ruleForm">
              {this.formItemsRender()}
            </ykc-form>
          </div>
        </ykc-drawer>
      );
    },
    data() {
      return {
        dropDefault: '',
        configs: [],
        ruleForm: {},
      };
    },
    computed: {
      showDrawer() {
        return this.show;
      },
      rules() {
        const defaultFormRules = {
          file: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.file.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        };
        const { customFormRules } = this.$props;

        return customFormRules ?? defaultFormRules;
      },
    },
    watch: {
      row: {
        immediate: true,
        deep: true,
        handler(newValue) {
          if (newValue) {
            this.doResetSomeField();
          }
        },
      },
      componentsConfigs: {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.configs = newValue;
        },
      },
    },

    methods: {
      formItemsRender() {
        return this.configs.map(item => {
          const TagName = item.componentName;
          const itemRules =
            typeof item.rules === 'function' ? item.rules(this.$refs, this) : item.rules;
          return (
            <ykc-form-item
              label={item.label}
              rules={itemRules}
              {...{
                props: {
                  rules: itemRules,
                },
              }}
              prop={item.key}
              key={item.key}>
              <TagName
                vModel={this.ruleForm[item.key]}
                {...item}
                {...{
                  props: {
                    model: this.ruleForm[item.key],
                    ...item,
                  },
                  on: {
                    handleChange: this.fileUpload,
                    handleRemove: this.handleRemove,
                    change: e => this.handleChange(e, item),
                    checkMessage: e => this.handleCheckMessage(e, item),
                  },
                }}
                dropDefault="dropDefault"
              />
            </ykc-form-item>
          );
        });
      },
      /**
       * 选择框选择操作
       */
      handleChange(v, obj) {
        // console.log('custom form handleChange:', v, obj);
        obj.change && obj.change(v, obj);
      },
      handleCheckMessage(errorMessage, item) {
        item.handleCheckMessage(errorMessage, item);
      },
      doResetSomeField() {
        this.ruleForm = {
          ...this.row,
        };
        this.$nextTick(() => {
          // this.$refs?.ruleForm?.reset();
          if (this.$refs.file) {
            this.$refs.file.fileList.length = 0;
          }
          this.$refs?.ruleForm?.clearValidate();
        });
      },
      // 监听操作模式改变 1 2 3
      onOperateModeChange(val) {
        console.log('onOperateModeChange val:', val);
        if (['1', '2'].includes(val)) {
          this.ruleForm.operateIdList = [];
        }
      },
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        this.$emit('dialog-close', false);
        done();
      },
      onCLose() {
        // this.showDrawer = false;
        this.$emit('dialog-close', false);
      },
      onConfirm(done) {
        console.log('done:', done);
      },
      handleRemove() {
        console.log('handleRemove called');
        this.ruleForm.file = undefined;
        this.validateFormFile();
      },
      validateFormFile() {
        this.$refs?.ruleForm?.validateField('file');
      },
      // 拷贝对象 不包含 file 字段
      copyFieldExcludeFile(copy) {
        Object.keys(this.ruleForm).forEach(key => {
          if (key !== 'file') {
            copy[key] = this.ruleForm[key];
          }
        });
      },
      fileUpload(file, fileList, isCheck) {
        console.log('fileUpload called');
        console.log('-> file', file);
        // check the value of isCheck
        if (isCheck) {
          if (file.name && file.name.length > 13) {
            this.$message({
              message: '文件名超过13个字符',
              type: 'warning',
            });
            this.$refs.file.fileList = [];
            return;
          }
          this.ruleForm.file = file.raw;
          // importMsg {"orgId":"1","operateObject","1","operateMode":"3"}
          const formData = new FormData();
          const copy = Object.create(null);
          this.copyFieldExcludeFile(copy);
          console.log('-> copy', copy);
          const msg = JSON.stringify(copy);
          console.log('upload msg:', msg);
          const param = {
            file: this.ruleForm.file,
            paramJson: msg,
          };
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });

          this.uploadMethod(formData)
            .then(res => {
              console.log('res---导入返回的信息:\n', res);
              this.ruleForm[this.$props.callBackFileProp] = res;
              this.$message({
                message: '导入文件成功',
                type: 'success',
              });
              this.validateFormFile();
            })
            .catch(error => {
              this.$refs.file.fileList = [];
              if (error) {
                this.validateFormFile();
              }
            });
        }
        this.validateFormFile();
      },
      onBeforeConfirm(done) {
        console.log('onBeforeConfirm called');
        this.$refs?.ruleForm?.validate((valid, obj) => {
          console.log('-> obj', obj);
          if (valid) {
            YkcDialog({
              showFooter: true,
              title: this.confirmTitle,
              type: 'warning',
              dialogType: 'feedback',
              desc: this.confirmDesc,
              onClose: closeDone => {
                closeDone();
              },
              onCancel: cancelDone => {
                cancelDone();
              },
              onConfirm: confirmDone => {
                console.log(confirmDone);
                this.submitForm(done);
                confirmDone();
              },
            });
          }
        });
        // 确认将所选终端的程序更新至 软件版本号 吗？
      },
      onSuccessConfirm() {
        YkcDialog({
          type: 'success',
          dialogType: 'feedback',
          showTitle: true,
          showCancelButton: false,
          title: this.successConfirmTitle,
          desc: this.successConfirmDesc,
          onClose(done) {
            console.log(done);
            // your code here
            done();
          },
          onCancel(done) {
            console.log(done);
            // your code here
            done();
          },
          onConfirm: done => {
            console.log(done);
            // your code here
            done();
          },
        });
      },
      submitForm(done) {
        this.$refs?.ruleForm?.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              // todo  校验规则正确后 请求接口
              const formData = Object.create(null);
              this.copyFieldExcludeFile(formData);
              Object.keys(formData).forEach(key => {
                if (this.submitExcludeKeys.includes(key)) {
                  delete formData[key];
                }
              });
              this.submitMethod(formData)
                // eslint-disable-next-line no-unused-vars
                .then(res => {
                  if (this.useSuccessConfirm) {
                    this.$emit('dialog-close', false);
                    this.$emit('refresh-list', true);
                    done();
                    this.onSuccessConfirm();
                    return;
                  }
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                  this.$emit('dialog-close', false);
                  this.$emit('refresh-list', true);
                  done();
                })
                .catch(err => {
                  console.error(err);
                  if (err) {
                    this.$message.warning(err?.resultDesc);
                  }
                });
            } catch (e) {
              console.error(e);
            }
          } else {
            console.error('error submit!!');
            this.formValid = false;
          }
        });
      },
    },
  };
</script>

<style scoped></style>
